import { RouteConfig } from "vue-router"

export const KeywordListRouteConfig: RouteConfig = {
  path: "/keywordList/:category",
  name: "KeywordList",
  component: () => import("%%/views/keywordList/KeywordList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
