import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IApiConnectionInfo } from "%%/labels/interfaces"

export class ApiConnectionInfoEn extends AbstractLocaleMessageObject implements IApiConnectionInfo {
  readonly companyId = "Company ID"
  readonly applicationId = "Connection source ID"
  readonly secret = "Secret key"
  readonly model = "Detection Model"
}
