import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonTitleHeaderEn extends AbstractLocaleMessageObject {
  readonly loginInput = "Login"

  readonly passwordChange = "Password Change"

  readonly passwordChangeRequest = "Password Reset"

  readonly eventList = "Event List"

  readonly eventDetail = "Event Detail"

  readonly eventItemDisplayFormatEdit = "Event Detail Display Customization Edit"

  readonly csvAuthoriHistory = "CSV Detection & Update History"

  readonly csvAuthori = "CSV Detection"

  readonly csvUpdate = "CSV Update"

  readonly eventReportNegative = "Detection Results Report"

  readonly negativeList = "Negative List"

  readonly negativeDetail = "Negative Detail"

  readonly negativeHistory = "Negative Registration History"

  readonly negativeAdd = "Negative Registration (Without Detection)"

  readonly csvNegative = "Negative Registration"

  readonly operatorList = "Operator List"

  readonly operatorAdd = "Operator Registration"

  readonly operatorDetail = "Operator Detail"

  readonly operatorEdit = "Operator Edit"

  readonly operatorRoleList = "Role List"

  readonly systemError = "System Error"

  readonly noPage = "Page Not Found"

  readonly allowedCompanyIpAddress = "Allowed IP Address"

  readonly whiteList = "White List"

  readonly whiteAdd = "White Registration (Without Detection)"

  readonly releasedRuleList = "Rule List"

  readonly apiConnectionInfo = "API Connection Information"

  readonly keywordCategoryList = "Keyword Category List"

  readonly keywordList = "Keyword List"

  readonly keywordAdd = "Keyword Registration"
}
