import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonTitleHeaderJa extends AbstractLocaleMessageObject {
  readonly loginInput: string = "ログイン"

  readonly passwordChange: string = "パスワード変更"

  readonly passwordChangeRequest: string = "パスワードリセット"

  readonly eventList: string = "イベント一覧"

  readonly eventDetail: string = "イベント詳細"

  readonly eventItemDisplayFormatEdit: string = "イベント詳細表示カスタマイズ編集"

  readonly csvAuthoriHistory: string = "CSV一括審査登録・更新履歴"

  readonly csvAuthori: string = "CSV一括審査登録"

  readonly csvUpdate: string = "CSV一括審査更新"

  readonly eventReportNegative: string = "審査結果レポート"

  readonly negativeList: string = "ネガティブ一覧"

  readonly negativeDetail: string = "ネガティブ詳細"

  readonly negativeHistory: string = "ネガティブ登録履歴"

  readonly negativeAdd: string = "ネガティブ登録（審査なし）"

  readonly csvNegative: string = "ネガティブ登録"

  readonly operatorList: string = "オペレータ情報一覧"

  readonly operatorAdd: string = "オペレータ情報登録"

  readonly operatorDetail: string = "オペレータ情報詳細"

  readonly operatorEdit: string = "オペレータ情報編集"

  readonly operatorRoleList: string = "ロール情報一覧"

  readonly systemError: string = "システムエラー"

  readonly noPage: string = "システムエラー"

  readonly allowedCompanyIpAddress = "許可IPアドレス"

  readonly whiteList = "ホワイト一覧"

  readonly whiteAdd = "ホワイト登録（審査なし）"

  readonly releasedRuleList = "ルール一覧"

  readonly apiConnectionInfo = "API接続情報"

  readonly keywordCategoryList = "キーワードカテゴリ一覧"

  readonly keywordList = "キーワード一覧"

  readonly keywordAdd = "キーワード登録"
}
