import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class KeywordListJa extends AbstractLocaleMessageObject {
  readonly backCategoryList = "カテゴリ一覧に戻る"
  readonly category = "カテゴリ"
  readonly delete = "削除"
  readonly addKeyword = "キーワード登録"
  readonly keyword = "キーワード"
  readonly searchPlaceholder = "キーワードを前方一致で検索。"
  readonly search = "検索"
  readonly updatedAt = "更新日時"
  readonly updatedBy = "更新者"
  readonly searchCondition = "検索条件"

  // キーワード登録用
  readonly backKeywordList = "キーワード一覧に戻る"
  readonly addDescription =
    "1キーワード最大{0}文字、{1}件まで登録できます。\nキーワードが登録済みの場合は、更新日時とオペレーター名を更新します。"
  readonly addPlaceholder = "登録するキーワードを入力してください。\n改行区切りで複数登録できます。"
  readonly msgLimit = "一度に{0}件以上は登録できません。"
  readonly msgLimitKeywordLength = "登録可能文字数 {0} を超過するキーワードが含まれています。"
}
