import { RouteConfig } from "vue-router"

export const KeywordAddRouteConfig: RouteConfig = {
  path: "/keywordList/:category/new",
  name: "KeywordAdd",
  component: () => import("%%/views/keywordAdd/KeywordAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
