import { RouteConfig } from "vue-router"

export const KeywordCategoryListRouteConfig: RouteConfig = {
  path: "/keywordCategoryList",
  name: "KeywordCategoryList",
  component: () => import("%%/views/keywordCategoryList/KeywordCategoryList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
