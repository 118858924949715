/**
 * グローバル変数を管理するためのクラスです。
 */
export namespace Const {
  export const GetRequestSuffix = "GetRequest"
  export const PostRequestSuffix = "PostRequest"
  export const PutRequestSuffix = "PutRequest"
  export const DeleteRequestSuffix = "DeleteRequest"
  export const GetResponseSuffix = "GetResponse"
  export const PostResponseSuffix = "PostResponse"
  export const PutResponseSuffix = "PutResponse"
  export const DeleteResponseSuffix = "DeleteResponse"
  export const HttpMethodGet = "GET"
  export const HttpMethodPost = "POST"
  export const HttpMethodPut = "PUT"
  export const HttpMethodDelete = "DELETE"
  export const SnackbarColorInfo = "info"
  export const SnackbarColorSuccess = "success"
  export const SnackbarColorError = "error"
  export const SnackbarTimeoutInfo = 5000
  export const SnackbarTimeoutSuccess = 3000
  export const SnackbarTimeoutError = 5000
  export const VueAppDeployEnvironmentProduction = "production"
  export const VueAppDeployEnvironmentTest = "tst"
  export const EnvironmentNameProduction = "PRD"
  export const EnvironmentNameTest = "TST"
  export const LocalStorageItemKey = "oplux"
  export const LocalStoragePermissionItemKey = "oplux-permission"

  /**
   * ApiBaseで取り扱うCommonResponseのresultの判定用
   */
  export const ApiBaseResultSuccess = "10"
  export const ApiBaseResultError = "20"

  /**
   * CommunicatorContextのstatus定義
   */
  export const CommunicatorContextStatusSuccess = ApiBaseResultSuccess
  export const CommunicatorContextStatusError = ApiBaseResultError
  export const CommunicatorContextStatusExecuting = "executing"
  export const CommunicatorContextStatusInitial = "initial"

  /**
   * CSS変数名の定義（変数自体の定義はApp.scss）
   */
  export const CSSVarOpluxAppBarColor = "--oplux-app-bar-color"
  export const CSSVarOpluxTableHeaderColor = "--oplux-table-header-color"

  /**
   * ページタイトルのフォーマット
   */
  export const DefaultTitleFormat = "不正検知システム - {0}"
  export const OPluxTitleFormat = "O-PLUX - {0}"
  export const FuseiCheckerTitleFormat = "不正チェッカー - {0}"

  /**
   * CommonPagingControlPartsのitemsPerPageの初期値
   */
  export const InitItemsPerPage = 200
}

/**
 * エラーコードの定義
 */
export namespace ErrorCode {
  export const AuthorizeErrorInvalidToken = "AuthorizeError.InvalidToken"
  export const AuthorizeErrorSourceIpNotAllowed = "AuthorizeError.SourceIpNotAllowed"
  export const InvalidDataDuplicate = "InvalidData.Duplicate"
  export const InvalidDataCompanyNone = "InvalidData.CompanyNone"
  export const InvalidDataTooManyRows = "InvalidData.TooManyRows"
  export const ServerErrorInternalFailure = "ServerError.InternalFailure"
  export const InformationNoDataFound = "Information.NoDataFound"
}

/**
 * cacco向けとshop向けの両画面用のグローバル変数を管理するためのクラスです。
 */
export namespace ScreenConst {
  /** 都道府県一覧（日本） */
  export const PrefecturesListJa = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ]
  /** コードマスタ情報取得API */
  export const CodeMasterListPostRequestCodeNameSettleStatus = "settle_status"
  export const CodeMasterListPostRequestCodeNameSettleMethod = "settle_method"
  export const CodeMasterListPostRequestCodeNameAuthStatus = "auth_status"
  export const CodeMasterListPostRequestCodeNameAutoAuthoriResult = "auto_authori_result"
  export const CodeMasterListPostRequestCodeNameManualAuthoriResult = "manual_authori_result"
  export const CodeMasterListPostRequestCodeNameNegativeAddType = "negative_add_type"
  export const CodeMasterListPostRequestCodeNameNegativeSettleCategory = "negative_settle_category"
  export const CodeMasterListPostRequestCodeNameNegativeOtherCategory = "negative_other_category"
  export const CodeMasterListPostRequestCodeNameCardIssuer = "card_issuer"
  export const CodeMasterListPostRequestCodeNameAutoAuthoriStatus = "auto_authori_status"
  export const CodeMasterListPostRequestCodeNameItemStock = "item_stock"
  export const CodeMasterListPostRequestCodeNameSex = "sex"
  export const CodeMasterListPostRequestCodeNameExistingCustomerFlg = "existing_customer_flg"
  export const CodeMasterListPostRequestCodeNameDeviceType = "device_type"
  export const CodeMasterListPostRequestCodeNameOmotionAuthoriResult = "omotion_authori_result"
  export const CodeMasterListPostRequestCodeNameContractDiv = "contract_div"
  export const CodeMasterListPostRequestCodeNameBusinessType = "business_type"
  /** ネガティブ情報登録履歴画面の登録日付（開始）の初期値のXヶ月前 */
  export const NegativeHistoryCreatedAtFromDefaultMonthsAgo = 1
  /** キーワード情報登録・履歴画面の登録日付（開始）の初期値のXヶ月前 */
  export const KeywordAddCreatedAtFromDefaultMonthsAgo = 3
  /** 登録契機 */
  export const UploadByCacco = "10"
  export const UploadByShop = "20"
  /** 加盟店ネガティブカテゴリの管理タイプ */
  export const CompanyNegativeCategoryControlTypeSettle = "01"
  export const CompanyNegativeCategoryControlTypeOther = "02"
  /** S3アクセスURL取得API */
  export const S3UrlPostRequestProcessingTypeUpload = "upload"
  export const S3UrlPostRequestProcessingTypeDownload = "download"
  export const S3UrlPostRequestCsvFileTypeCsvKeyword = "CsvKeyword"
  export const S3UrlPostRequestCsvFileTypeCsvNegativeEvent = "CsvNegativeEvent"
  export const S3UrlPostRequestCsvFileTypeCsvNegativeItem = "CsvNegativeItem"
  export const S3UrlPostRequestCsvFileTypeCsvAuthori = "CsvAuthori"
  export const S3UrlPostRequestCsvFileTypeCsvUpdate = "CsvUpdate"
  /** 画面ID */
  export const ScreenIdEventOkDlg = "EventOkDlg"
  export const ScreenIdEventNgDlg = "EventNgDlg"
  export const ScreenIdEventHoldDlg = "EventHoldDlg"
  export const ScreenIdEventNegativeDlg = "EventNegativeDlg"
  export const ScreenIdEventMemoDlg = "EventMemoDlg"
  export const ScreenIdEventAssignDlg = "EventAssignDlg"
  export const ScreenIdEventLabelDlg = "EventLabelDlg"
  export const ScreenIdEventCsvDownloadDlg = "EventCsvDownloadDlg"
  /** 審査結果情報詳細表示項目変更部品の動作モード */
  export const EventItemDisplayFormatEditPartsModeEventProperty = 0
  export const EventItemDisplayFormatEditPartsModeItemCategory = 1
  export const EventItemDisplayFormatEditPartsModeFixedItems = 2
  export const EventItemDisplayFormatEditPartsModeOther = 3
  /** 審査ステータス */
  export const AuthStatusAuthori = "00"
  export const AuthStatusEscalation = "10"
  export const AuthStatusUnfixed = "30"
  /** 目視審査結果 */
  export const ManualAuthoriResultOk = "10"
  export const ManualAuthoriResultNg = "20"
  export const ManualAuthoriResultHold = "30"
  /** ダイアログを閉じてから次の処理を行うまでの時間（ミリ秒） */
  export const ClosingDialogToNextProcessing = 50
  /** v-data-tableのrowのclass */
  export const TableRowClassInvalid = "grey"
  export const TableRowClassSelected = "grey lighten-2"
  /** 検索種別 */
  export const SearchStatusQuick = 0
  export const SearchStatusDetail = 1
  /** CSVダウンロード依頼API */
  export const EventListCsvDelimiterComma = 0
  export const EventListCsvDelimiterTab = 1
  /** 審査結果情報一覧取得API */
  export const EventListCustomerTypePurchaser = 0
  export const EventListCustomerTypeShipping = 1
  export const EventListCustomerTypeBoth = 2
  /** ネガティブ情報登録API */
  export const EventNegativeOperationTypeNothing = 0
  export const EventNegativeOperationTypeRegistration = 1
  export const EventNegativeOperationTypeRelease = 2
  export const CodeMasterListPostRequestCodeNameCustomerType = "customer_type"
  /** ネガティブ情報一覧取得API */
  export const NegativeListPostRequestSearchTypeShopMemberId = "shop_member_id"
  export const NegativeListPostRequestSearchTypeNormalizedLastName = "normalized_last_name"
  export const NegativeListPostRequestSearchTypeNormalizedFirstName = "normalized_first_name"
  export const NegativeListPostRequestSearchTypeZipcode = "zipcode"
  export const NegativeListPostRequestSearchTypeFullAddress = "full_address"
  export const NegativeListPostRequestSearchTypeTel = "tel"
  export const NegativeListPostRequestSearchTypeMailAccount = "mail_account"
  export const NegativeListPostRequestSearchTypeMailDomain = "mail_domain"
  export const NegativeListPostRequestSearchTypeCookie = "cookie"
  export const NegativeListPostRequestSearchTypeSettleCategory = "settle_category"
  export const NegativeListPostRequestSearchTypeOtherCategory = "other_category"
  export const NegativeListPostRequestSearchTypeIpaddress = "ipaddress"
  export const NegativeListPostRequestSearchTypeEventId = "event_id"
  export const NegativeListPostRequestSearchTypeShopEventId = "shop_event_id"
  /** 個別ネガティブ情報登録API */
  export const NegativeItemPostRequestItemTypeNormalizedName = "normalized_name"
  export const NegativeItemPostRequestItemTypeTel = "tel"
  export const NegativeItemPostRequestItemTypeMobileTel = "mobile_tel"
  export const NegativeItemPostRequestItemTypeAddress = "address"
  export const NegativeItemPostRequestItemTypeIpaddress = "ipaddress"
  export const NegativeItemPostRequestItemTypeCookie = "cookie"
  export const NegativeItemPostRequestItemTypeMail = "mail"
  export const NegativeItemPostRequestItemTypeMobileMail = "mobile_mail"
  /** ネガティブCSV登録依頼API */
  export const CsvNegativeEventPostRequestUploadTypeShopEventId = "00"
  export const CsvNegativeEventPostRequestUploadTypeEventId = "10"
  export const CsvNegativeEventPostRequestUploadTypeMemberId = "20"
  export const CsvNegativeEventPostRequestUploadTypeCsv = "30"
  export const CsvNegativeEventPostRequestCustomerTypePurchaser = "10"
  export const CsvNegativeEventPostRequestCustomerTypeShipping = "20"
  export const CsvNegativeEventPostRequestCustomerTypeBoth = "30"
  export const CsvNegativeEventPostRequestCustomerTypeNegativeRegisterCancelRegistration = "0"
  export const CsvNegativeEventPostRequestCustomerTypeNegativeRegisterRegistration = "1"
  /** MIMEタイプ */
  export const MimeTypeCsv = "text/csv"
  /** Moment Unit */
  export const MomentUnitMonths = "months"
  /** 拡張子 */
  export const ExtensionCsv = "csv"
  /** CSV審査依頼登録API */
  export const CsvAuthoriPostRequestPriorityTypeSpeed = "10"
  export const CsvAuthoriPostRequestPriorityTypeAccuracy = "20"
  /** CSV更新依頼登録API */
  export const CsvUpdatePostRequestUploadTypeScreenShopEventId = "00"
  export const CsvUpdatePostRequestUploadTypeScreenEventId = "10"
  export const CsvUpdatePostRequestUploadTypeCsv = "30"
  export const CsvUpdatePostRequestDeleteTypeDoNot = "0"
  export const CsvUpdatePostRequestDeleteTypeDo = "1"
  export const CsvUpdatePostRequestUnregisterDoNot = "0"
  export const CsvUpdatePostRequestUnregisterDo = "1"
  /** 利用可能機能グループの権限：00：できる */
  export const RolePermissionWritable = "00"
  /** 利用可能機能グループの権限：10：できない */
  export const RolePermissionReadable = "10"
  /** 利用可能機能グループの権限：20：機能を提供しない */
  export const RolePermissionNotAvailable = "20"
  /** 事業者形態 */
  export const BusinessTypeDeferredPayment = "10"
  export const BusinessTypeDirectSales = "20"
  /** 強制イベントNGのルールコード */
  export const ForcedEventNgRuleCode = "FORCED_EVENT_NG"
  /** イベントリストの選択上限(CSVダウンロードの上限件数) */
  export const EventSelectionLimit = 150000
}

/**
 * SessionStorageのキー
 */
export namespace SessionStorageKey {
  // フレームワーク利用
  export const SYSTEM_ERROR = "systemError"
  export const SNACKBAR_QUEUE = "snackbarQueue"
  export const MENU_PERMISSIONS = "menuPermissions"
  export const BEFORE_ROUTE_LEAVE_INFO = "beforeRouteLeaveInfo"
  export const RESERVED_KEYS = [SYSTEM_ERROR, SNACKBAR_QUEUE, MENU_PERMISSIONS, BEFORE_ROUTE_LEAVE_INFO]

  // 遷移先画面のSessionStorageKeyの接尾語 一覧画面から遷移する場合に参照
  export const TRANSIT_DESTINATION_SUFFIX = ":referrer"

  // 各種画面遷移用
  export const ADMIN_USER_PAGE = "caccoUserPage"
  export const ADMIN_USER_ID = "userId"
  export const ADMIN_ROLE = "adminRole"
  export const AUTHORI_MODEL = "authoriModel"
  export const COMPANY = "company"
  export const NEGATIVE = "negative"
  export const EVENT = "event"
  export const KEYWORD_SEARCH = "keywordSearch"
  export const KEYWORD_PAGE = "keywordPage"
  export const LABEL_ID = "labelId"
  export const OPERATOR_ID = "operatorId"
  export const OPERATOR_PAGE = "operatorPage"
  export const OPERATOR_ROLE = "operatorRole"
  export const RELEASE_COMPANY_ID = "companyIdKey"
  export const RULE_MASTER_ADD = "ruleMasterAddTransitKey"
  export const RULE_MASTER_LIST = "ruleMasterListTransitKey"
  export const RULE_MASTER_EDIT = "ruleMasterEditTransitKey"
  export const RULE_MASTER_DETAIL = "ruleMasterDetailTransitKey"
  export const COMBINAT_JUDGMENT_ADD = "CombinatJudgmentAddKey"
  export const COMBINAT_JUDGMENT_DETAIL = "combinatJudgmentDetailTransitKey"
  export const OPERATOR_COMPANY_ID = "operatorCompanyId"
}
