import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IKeywordList } from "%%/labels/interfaces"

export class KeywordListEn extends AbstractLocaleMessageObject implements IKeywordList {
  readonly backCategoryList = "Back to Category List"
  readonly category = "Category"
  readonly delete = "Delete"
  readonly addKeyword = "Add Keyword"
  readonly keyword = "Keyword"
  readonly searchPlaceholder = "Search by keyword prefix."
  readonly search = "Search"
  readonly updatedAt = "Updated At"
  readonly updatedBy = "Updated By"
  readonly searchCondition = "Search Condition"

  // キーワード登録用
  readonly backKeywordList = "Back to Keyword List"
  readonly addDescription =
    "You can register up to {1} keywords with a maximum of {0} characters each.\nIf the keyword is already registered, the update date and operator name will be updated."
  readonly addPlaceholder =
    "Enter the keyword to register.\nYou can register multiple items by separating them with line breaks."
  readonly msgLimit = "You cannot register more than {0} items at a time."
  readonly msgLimitKeywordLength =
    "A keyword that exceeds the maximum number of characters that can be registered {0} is included."
}
